<template>
<div>
  <v-row class="d-flex justify-center mt-0">
    <v-col cols="12">
      <v-expansion-panels v-model="panel">
        <v-expansion-panel readonly>
          <v-expansion-panel-header class="py-0 px-4 text-h5">
            <div>
              <v-icon class="mb-1" left>fas fa-filter</v-icon>
              Filtros
            </div>
            <template v-slot:actions>
              <v-btn icon @click.stop="panel = panel === 1 ? 0 : 1">
                <v-icon>fas fa-chevron-down</v-icon>
              </v-btn>
            </template>
          </v-expansion-panel-header>
          <v-expansion-panel-content class="">
            <v-form @submit.prevent="buscar()"> 
              <v-row class="pt-2 pb-0">
                <v-col cols="6" sm="3" md="3" class="py-1">
                  Fecha desde
                  <FechaPickerVue
                    v-model="filtro.fecha_desde"
                    clearable hideDetails
                    :fecha_maxima="moment(new Date()).format('DD/MM/YYYY')"
                  />
                </v-col>
                <v-col cols="6" sm="3" md="3" class="py-1">
                  Fecha Hasta
                  <FechaPickerVue
                    v-model="filtro.fecha_hasta"
                    clearable hideDetails
                    :fecha_maxima="moment(new Date()).format('DD/MM/YYYY')"
                  />
                </v-col>
                <v-col cols="12" sm="6" md="2" class="py-1">
                  Sucursal
                  <v-autocomplete
                    v-model="filtro.sucursal_codigo"
                    item-text="nombre"
                    item-value="id"
                    tabindex="1"
                    clearable
                    :items="sucursales"
                    hide-details
                    outlined
                    dense
                    @change="getPuntoVenta()"
                  ></v-autocomplete>
                </v-col>
                <v-col cols="12" sm="6" md="4" class="py-1">
                  Punto de Venta
                  <v-autocomplete
                    v-model="filtro.local_codigo" 
                    item-text="pto_vta_nombre"
                    item-value="pto_vta_codigo"
                    tabindex="1"
                    :items="selectPtoVenta"
                    clearable
                    hide-details
                    outlined
                    dense
                  ></v-autocomplete>
                </v-col>
                <v-col cols="12" sm="6" md="3" class="py-1">
                  Rubro
                  <v-autocomplete
                    v-model="filtro.rubro_codigo"
                    item-text="rubro_nombre"
                    item-value="rubro_codigo"
                    tabindex="1"
                    :items="rubros"
                    hide-details
                    outlined
                    dense clearable
                    @change="getCategorias()"
                  ></v-autocomplete>
                </v-col>
                <v-col cols="12" sm="6" md="3" class="py-1">
                  Categoría
                  <v-autocomplete
                    v-model="filtro.categoria_codigo"
                    item-text="categoria_nombre"
                    item-value="categoria_codigo"
                    tabindex="1"
                    :items="catSelect"
                    hide-details
                    outlined
                    dense clearable
                    ></v-autocomplete>
                </v-col>
                <v-col cols="12" sm="6" md="3" class="pt-1">
                  Artículo código
                  <v-text-field
                    v-model="filtro.articulo_codigo"
                    type="number"
                    tabindex="1"
                    hide-details
                    outlined
                    dense
                    clearable
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" md="3" class="pt-1">
                  <BtnFiltroVue
                    :loading="load"
                    clase="mt-0"
                    @clear="limpiar()"
                  />
                </v-col>
              </v-row>
            </v-form>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </v-col>
    <v-col cols="12" class="py-0">
      <v-data-table
        class="cebra elevation-2 mt-2"
        sort-by="deposito_nombre"
        :headers="headers"
        :items="ventas"
        :loading="load"
        :search="search"
        :footer-props="{'items-per-page-options':[10, 15, 50, 100]}"
        dense
        item-key="id_row"
      >
        <template v-slot:top>
            <v-row class="d-flex justify-end pa-2" no-gutters>
              <v-col cols="6" sm="9" md="10" class="d-flex justify-start align-center">
                <DownloadDataTableVue
                  name="Control Contribucion"
                  :data="ventas"
                  :headers="headers_excel"
                />
              </v-col>
              <v-col cols="6" sm="3" md="2">
                <SearchDataTableVue
                  v-model="search"
                />
              </v-col>
            </v-row>
        </template>
        <template v-slot:no-data>
          <v-alert
            class="mx-auto mt-4"
            max-width="400"
            type="warning"
            border="left"
            dense
            text
          >
            No hay datos para los filtros seleccionados
          </v-alert>
        </template>
        <template v-slot:[`item.acciones`]="{ item }">

          <v-btn 
            small icon
            color="info"
            title="Ver venta"
            :href="`http://cbbsrl.dynalias.com:8800/bb_web/public/venta/edit/${item.id}`"
            target="_blank"
          >
          <v-icon small>
            fas fa-eye
          </v-icon>

          </v-btn>
        </template>
      <template
        v-for="header in headers.filter((header) => header.hasOwnProperty('formatter'))"
        v-slot:[`item.${header.value}`]="{ value }"
      >
        {{ header.formatter(value) }}
      </template>
      <template v-slot:[`item.articulo_datos`]="{item}">
        {{ item.articulo_codigo + " - "+ item.articulo }}
      </template>
      </v-data-table> 
    </v-col>
  </v-row>
</div>
</template>

<script>
import moment from 'moment'
import BtnFiltroVue from '../../components/util/BtnFiltro.vue'
import FechaPickerVue from '../../components/util/FechaPicker.vue'
import SearchDataTableVue from '../../components/util/SearchDataTable.vue'
import { format_money, order_list_by } from '../../util/utils'
import { mapState } from 'vuex'
import DownloadDataTableVue from '../../components/util/DownloadDataTable.vue'

export default {
  name: 'IndexContribucionFranquicia',
  data(){
    return{
      moment: moment,
      search:'',
      load: false,
      panel: 0,
      //initContribFranquicias
      franquicias:[],
      rubros:[],
      categorias:[],
      //filtro del panel principal
      filtro:{
        fecha_desde: null,
        fecha_hasta: null,
        sucursal_codigo: null,
        local_codigo: null, 
        rubro_codigo: null, 
        categoria_codigo: null, 
        articulo_codigo: null,
      },
      selectPtoVenta: [],
      catSelect:[],
      //data-table
      headers:[
        { text: 'Id', value: 'id'},
        { text: 'Fecha', value: 'fecha'},
        { text: 'Artículo', value: 'articulo_datos'},
        { text: 'FP', value: 'forma_pago'},
        { text: 'Importe', value: 'importe_s_iva', formatter: format_money},
        { text: 'Costo', value: 'costo_total_x_item_s_iva', formatter: format_money},
        { text: 'Contrib. Marg.', value: 'contribucion_total', formatter: format_money},
        { text: 'Acciones', value: 'acciones', align: 'center', sortable: false, filterable: false},
      ],
      ventas:[],
      //headers para el excel
      headers_excel:{
        'Id': 'id',
        'Fecha': 'fecha',
        'Artículo Código': 'articulo_codigo',
        'Artículo Nombre': 'articulo',
        'Forma Pago': 'forma_pago',
        'Importe': 'importe_s_iva',
        'Costo': 'costo_total_x_item_s_iva',
        'Contribución Marginal': 'contribucion_total'
      }
    }
  },
  created(){
    this.init();
  },
  methods:{
    async init(){
      this.$store.state.loading = true
      await this.$store.dispatch('contribucionFranquicia/initContribucionFranquicias')
        .then((res) => {
          this.categorias = res.categorias;          order_list_by(this.categorias, 'categoria_nombre');
          this.franquicias = res.franquicias;          order_list_by(this.franquicias, 'caja_jerarquia_nombre');
          this.rubros = res.rubros;     order_list_by(this.rubros, 'rubro_nombre');
        })
        .catch(error => {
          this.$store.dispatch('show_snackbar', {
            text: error.message,
            color: 'error'
          })
        })
      this.$store.state.loading = false
    },
    async buscar(){
      if(!this.filtro.fecha_desde){
        return this.$store.dispatch('show_snackbar', {
          text: 'Seleccione una Fecha desde',
          color: 'info'
        })
      }
      this.ventas = [];
      this.load = true;
      await this.$store.dispatch('contribucionFranquicia/buscarVentasFranquicias', this.filtro)
        .then((res) => {
          this.ventas = res.ventas;
        })
        .catch(error => {
          this.$store.dispatch('show_snackbar', {
            text: error.message,
            color: 'error'
          })
        })
      this.load = false;
    },
    limpiar(){
      this.ventas = [];
      this.search = '';
      this.selectPtoVenta = [];
      this.filtro = {
        fecha_desde: null,
        fecha_hasta: null,
        sucursal_codigo: null,
        local_codigo: null, 
        rubro_codigo: null, 
        categoria_codigo: null, 
        articulo_codigo: null,
      }
    },
    getPuntoVenta(){
      if(this.filtro.sucursal_codigo){
        this.filtro.local_codigo = null;
        this.selectPtoVenta = this.franquicias.filter(elem => elem.sucursal_codigo == this.filtro.sucursal_codigo);
      }else{
        this.selectPtoVenta = [];
        this.filtro.local_codigo = null;
      }
    },
    open_venta(item){
      let path = ''
      if (item) {
        path = `/http://cbbsrl.dynalias.com:8800/bb_web/public/venta/edit/${item.id}`
      }
      //const routeData = this.$router.resolve({ path: path })
      window.open(path, '_blank')
    },
    getCategorias(){
      if(this.filtro.rubro_codigo){
        this.catSelect = this.categorias.filter(elem => elem.rubro_codigo === this.filtro.rubro_codigo);
      }else{
        this.filtro.categoria_codigo = null;
        this.catSelect = [];
      }
    }
  },
  computed:{
    ...mapState(['sucursales']),
  },
  components:{
    SearchDataTableVue, BtnFiltroVue, FechaPickerVue, DownloadDataTableVue
  }
}
</script>

<style>

</style>